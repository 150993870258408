import Link from 'next/link'
import React from 'react'
import type { TMenuLinkProps } from '../interface'

const MenuLink = ({ elementType, path, children, onClick }: TMenuLinkProps) => {
  if (elementType === 'a') {
    return (
      <Link onClick={onClick} passHref href={path}>
        {children}
      </Link>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default MenuLink
