import { createCtx } from '@okam/core-lib'
import { useCallback, useMemo } from 'react'
import { Item, useTabListState } from 'react-stately'
import useOverlayHook from '../../components/Lightbox/hooks/overlay'
import type { TSidePanelContext, TSidePanelProviderProps } from './interface'

export const [useSidePanel, SidePanelProvider] = createCtx<TSidePanelContext>()

const defaultTabList = (
  <Item key="empty" title="empty">
    <p>empty</p>
  </Item>
)

export function SidePanelContextProvider({ children, defaultSelectedKey, tabProps }: TSidePanelProviderProps) {
  const tabState = useTabListState(tabProps ?? { children: defaultTabList })
  const onCloseCallBack = useCallback(() => {
    tabState.setSelectedKey(defaultSelectedKey)
  }, [tabState, defaultSelectedKey])

  const { setSelectedKey } = tabState

  const {
    state: overlayState,
    closeButtonProps,
    closeButtonRef,
    openButtonProps,
    openButtonRef,
  } = useOverlayHook(false, undefined, onCloseCallBack)

  const value = useMemo<TSidePanelContext>(
    () => ({
      defaultSelectedKey,
      tabState,
      overlayState,
      onCloseCallBack,
      setSelectedKey,
      buttonProps: {
        closeButtonProps,
        closeButtonRef,
        openButtonProps,
        openButtonRef,
      },
    }),
    [
      tabState,
      overlayState,
      defaultSelectedKey,
      setSelectedKey,
      closeButtonProps,
      closeButtonRef,
      openButtonProps,
      openButtonRef,
      onCloseCallBack,
    ],
  )

  return <SidePanelProvider value={value}>{children}</SidePanelProvider>
}
