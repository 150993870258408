import isEqual from 'lodash.isequal'
import React, { memo } from 'react'

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx="10.579" cy="10.579" r="6.579" stroke="#000" strokeWidth="2" />
    <path stroke="currentColor" strokeLinecap="square" strokeWidth="2" d="M16.415 14.999 21 19.585" />
  </svg>
)

const Search = memo(SvgComponent, isEqual)
export default Search
