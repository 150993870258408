import { SearchInput } from '@okam/shared-ui'
import { useCallback } from 'react'
import { useSearchIcon } from '../../../providers/ProductSearch'
import Button from '../../Button'
import ArrowLeft from '../../icons/ArrowLeft'
import Search from '../../icons/Search'
import type { TMenuHeaderProps } from '../interface'
import 'twin.macro'

const MenuHeader = ({
  searchCallback,
  isSearching,
  backBtnLabel,
  searchInputPlaceholder,
  searchAriaLabel,
}: TMenuHeaderProps) => {
  const { isSearching: isIconSearching, setIsSearching } = useSearchIcon()

  const searchInputRef = useCallback(
    (ref: HTMLInputElement) => {
      if (ref && isIconSearching) {
        ref.focus()
        setIsSearching(false)
      }
    },
    [isIconSearching, setIsSearching],
  )

  return (
    <div tw="relative">
      {isSearching && (
        <Button
          iconPosition="left"
          tw="absolute w-auto -top-14 left-0"
          handlePress={() => searchCallback(undefined)}
          buttonStyle="hollow"
        >
          <ArrowLeft tw="mr-2 my-auto inline transform origin-center mb-[0.05em]" />
          <span tw="text-lg">{backBtnLabel}</span>
        </Button>
      )}

      <SearchInput
        inputRef={searchInputRef}
        tw="font-body bg-[#e3eff9] border-[#e3eff9] placeholder:text-[#444444] placeholder:text-lg placeholder:font-body placeholder:font-light placeholder:tracking-widest"
        placeholder={searchInputPlaceholder ?? undefined}
        onChange={searchCallback}
        label={<span tw="sr-only">{searchInputPlaceholder}</span>}
        icon={
          <div tw="bg-primary-1 rounded-full flex justify-center items-center mr-2 w-[52px] h-[52px]">
            <Search />
          </div>
        }
        id="search-menu"
        name="search-menu"
        ariaLabel={searchAriaLabel ?? ''}
      />
    </div>
  )
}

export default MenuHeader
