import React from 'react'
import type { TFooterLinksContainerProps } from './interface'

const FooterLinksContainer = (props: TFooterLinksContainerProps) => {
  const { children, ...rest } = props

  return (
    <div {...rest} tw="lg:grid lg:grid-cols-2 lg:grid-flow-row lg:justify-items-start">
      {children}
    </div>
  )
}

export default FooterLinksContainer
