import { createCtx } from '@okam/core-lib'
import { useMemo } from 'react'
import { SidePanelContextProvider } from '../SidePanel'
import type { IMenuProviderProps, TMenuContext, TMenuProps } from './interface'

export const [useMenu, MenuProvider] = createCtx<TMenuProps>()

export function MenuContextProvider({
  children,
  defaultSelectedKey,
  tabs,
  openBtn,
  closeBtn,
  ...rest
}: IMenuProviderProps) {
  const value = useMemo<TMenuContext>(() => ({ openBtn, closeBtn }), [openBtn, closeBtn])

  return (
    <MenuProvider value={value}>
      <SidePanelContextProvider
        {...(tabs
          ? {
              tabProps: {
                ...rest,
                children: tabs,
                defaultSelectedKey,
              },
            }
          : {})}
        defaultSelectedKey={defaultSelectedKey.toString()}
      >
        {children}
      </SidePanelContextProvider>
    </MenuProvider>
  )
}
