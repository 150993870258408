import { useSidePanel } from '@okam/shared-ui/src'
import tw from 'twin.macro' // eslint-disable-line
import { ButtonWithForwardRef } from '../../Button'
import CloseBtn from '../../icons/CloseBtn'

const CloseButton = () => {
  const { buttonProps } = useSidePanel()

  return (
    <div tw="absolute top-6 h-auto left-6 lg:left-12 z-50">
      <ButtonWithForwardRef {...buttonProps?.closeButtonProps} ref={buttonProps?.closeButtonRef} tw="!p-0">
        <CloseBtn color="black" />
      </ButtonWithForwardRef>
    </div>
  )
}

export default CloseButton
