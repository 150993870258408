import { useSearchField } from '@react-aria/searchfield'
import { useSearchFieldState } from '@react-stately/searchfield'
import { useRef } from 'react'
import { FocusRing, mergeProps, useFocusRing } from 'react-aria'
import tw, { css } from 'twin.macro'
import type { TSearchInputProps } from './SearchInput.interface'
import { disableWKStyles, getIconVariant, StyledInput } from './SearchInput.styles'

const SearchInput = (props: TSearchInputProps) => {
  const {
    id,
    label,
    ariaLabel,
    name,
    placeholder,
    isDisabled,
    isError,
    errorMessage,
    value,
    onBlur,
    onChange,
    inputRef,
    required = false,
    icon,
    bgColor,
    buttonColor,
  } = props

  const state = useSearchFieldState(props)
  const ref = useRef<HTMLInputElement | null>(null)
  const { labelProps, errorMessageProps, inputProps } = useSearchField(props, state, ref)
  const { focusProps, isFocusVisible } = useFocusRing()
  return (
    <div>
      {label && (
        <label htmlFor={id} {...labelProps}>
          {label}
        </label>
      )}
      <FocusRing {...focusProps} focusRingClass="focus-ring">
        <div css={[tw`relative`, disableWKStyles]}>
          <StyledInput
            {...mergeProps(inputProps, props)}
            type="search"
            ref={(e) => {
              inputRef?.(e)
              ref.current = e
            }}
            id={id}
            name={name}
            aria-label={ariaLabel}
            placeholder={placeholder}
            isDisabled={isDisabled}
            required={required}
            isError={isError}
            isFocused={isFocusVisible}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            css={[
              tw`[-webkit-appearance:none] [-moz-appearance:none]`,
              css`
                background-color: ${bgColor};
              `,
            ]}
            tw="!font-display"
          />
          {state.value === '' && icon && (
            <div
              css={[
                tw`absolute right-0 text-inherit rounded-full top-[50%] translate-y-[-50%]`,
                getIconVariant(isDisabled, isError),
                css`
                  background-color: ${buttonColor};
                `,
              ]}
            >
              {icon}
            </div>
          )}
        </div>
      </FocusRing>
      {isError && errorMessage && (
        <div {...errorMessageProps} tw="text-red-600 text-sm px-2 ml-6 lg:ml-4">
          {errorMessage}
        </div>
      )}
    </div>
  )
}

export default SearchInput
