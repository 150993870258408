import { HeroImg } from '@okam/brand-ui'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { toDirectusUrl, useDirectus } from 'lib/directus'

interface TLinkHeroImgProps {
  onTileClick?: (text: string, textFr: string, link: string) => void
}

const LinkHeroImg = ({ onTileClick }: TLinkHeroImgProps) => {
  const { t } = useTranslation('common')

  const settings = useDirectus()

  const { translations, mondou_logo: mondouLogo, renspet_logo: renspetLogo } = settings?.settings ?? {}
  const { buyCta, renCta } = translations?.[0] ?? {}

  const monLogo = toDirectusUrl(mondouLogo?.id, mondouLogo?.filename_download) ?? ''
  const renLogo = toDirectusUrl(renspetLogo?.id, renspetLogo?.filename_download) ?? ''

  return (
    <div id="find-us-website" tw="flex flex-row justify-center items-start px-4">
      {monLogo && buyCta && (
        <HeroImg
          link={buyCta ?? ''}
          onClick={() => onTileClick?.(t('LOCATIONSTORE.WEBSITE'), t('LOCATIONSTORE.WEBSITE', { lng: 'fr' }), buyCta)}
          ariaLabel="#"
          text={t('LOCATIONSTORE.WEBSITE')}
        >
          <Image src={monLogo} alt="white cat" width={300} height={300} tw="mx-auto" />
        </HeroImg>
      )}
      {renLogo && renCta && (
        <HeroImg
          link={renCta ?? ''}
          ariaLabel="#"
          onClick={() => onTileClick?.(t('LOCATIONSTORE.WEBSITE'), t('LOCATIONSTORE.WEBSITE', { lng: 'fr' }), renCta)}
          text={t('LOCATIONSTORE.WEBSITE')}
        >
          <Image src={renLogo} alt="white cat" width={300} height={300} tw="mx-auto" />
        </HeroImg>
      )}
    </div>
  )
}

export default LinkHeroImg
