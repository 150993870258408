import { Logo, Button, useSearchIcon } from '@okam/brand-ui/index'
import { useMenu, useSidePanel } from '@okam/shared-ui'
import { useTranslation } from 'next-i18next'
import { theme } from 'twin.macro'
import { trackImportantClick } from 'gtm-analytics/tracking'

interface HeaderProps {
  logoColor?: string | null
  searchLabel?: string | null
  homeLink?: string
}

const Header = ({ logoColor, searchLabel, homeLink }: HeaderProps) => {
  const { openBtn } = useMenu()
  const { t } = useTranslation('common')
  const primaryColor = theme`colors.primary.1`
  const { setSelectedKey, overlayState } = useSidePanel()

  const { setIsSearching } = useSearchIcon()

  return (
    <div>
      <div tw="fixed z-[1100] top-0">{openBtn?.()}</div>
      <div tw="absolute px-4 py-4 z-[90] flex scale-75 sm:scale-[0.85] justify-center w-full">
        <Logo color={logoColor ?? primaryColor} link={homeLink} tw="z-[100]" />
      </div>
      <div tw="hidden md:flex absolute w-full justify-end px-4 py-6 gap-4">
        <Button
          tw="z-[100] text-black"
          backgroundColor={primaryColor}
          buttonStyle="default"
          hoverColor={primaryColor}
          outlineColor={primaryColor}
          variant="primary"
          handlePress={() => {
            overlayState.open()
            setSelectedKey?.('buy')
            trackImportantClick({
              click_type: 'cta',
              interaction_location: 'page',
              link_url: null,
              link_text: t('MENU.BUY_CTA'),
              link_text_fr: t('MENU.BUY_CTA', { lng: 'fr' }),
            })
          }}
        >
          {t('MENU.BUY_CTA')}
        </Button>
        <Button
          tw="z-[100] text-black"
          aria-label={searchLabel}
          as="button"
          variant="iconOnly"
          backgroundColor="transparent"
          icon="Search"
          handlePress={() => {
            setIsSearching(true)
            overlayState.open()
          }}
        />
      </div>
    </div>
  )
}

export default Header
