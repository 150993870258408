import React from 'react'
import Div100vh from 'react-div-100vh'
import tw from 'twin.macro'
import { useSidePanel } from '../../providers/SidePanel'
import RenderWithSlide from '../../transitions/RenderWithSlide'
import SidePanelTransition from '../../transitions/SidePanelTransition'
import Modal from '../Modal'
import type { TSidePanelProps } from './interface'
import { Div100vhStyle } from './styles'

const SidePanel = (props: TSidePanelProps) => {
  const {
    children,
    containerStyle,
    closeBtnRender,
    innerDivStyle,
    onCloseCallBack,
    TransitionAnimation = RenderWithSlide,
  } = props

  const { overlayState } = useSidePanel()

  return (
    <Modal
      onCloseCallBack={onCloseCallBack}
      state={overlayState}
      transitionComponent={SidePanelTransition as (props: unknown) => JSX.Element}
    >
      <Div100vh css={containerStyle ?? Div100vhStyle}>
        {closeBtnRender && closeBtnRender()}
        <TransitionAnimation isVisible={overlayState.isOpen}>
          <div css={tw`h-full w-full flex`}>
            <div css={innerDivStyle ?? tw`bg-black h-full w-full`}>{children}</div>
          </div>
        </TransitionAnimation>
      </Div100vh>
    </Modal>
  )
}

export default React.memo(SidePanel)
