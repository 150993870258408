import type { MenuItem } from '@okam/shared-ui'
import { useSidePanel } from '@okam/shared-ui'
import tw from 'twin.macro'
import Button from '../../Button'
import ArrowRight from '../../icons/ArrowRight'
import MenuLink from './MenuLink'

const SingleMenuItem = (menuItem: MenuItem & { size: string }) => {
  const { tabState } = useSidePanel()
  const { setSelectedKey } = tabState
  const { id, path, label, size, onClick } = menuItem
  const itemKey = path?.substring(1)
  const elementType = path?.charAt(0) === '#' ? 'button' : 'a'

  if (path == null) {
    return null
  }

  const handlePress = () => {
    if (itemKey == null) {
      return
    }

    setSelectedKey(itemKey)
  }

  const textStyle =
    size === 'small'
      ? tw`text-[1.75rem] font-light tracking-widest`
      : tw`text-[2.5rem] font-semibold tracking-widest [line-height: 110%]`

  return (
    <MenuLink onClick={onClick} elementType={elementType} path={path}>
      <Button
        key={`${elementType}-${id}`}
        {...(elementType === 'button' && { handlePress })}
        iconPosition="left"
        tw="text-black p-2 m-0 min-w-[auto] text-left min-h-[auto] bg-none"
        as="button"
        buttonStyle="hollow"
      >
        <span css={textStyle}>
          {label}
          <ArrowRight tw="ml-1 my-auto inline transform origin-center mb-[0.05em]" />
        </span>
      </Button>
    </MenuLink>
  )
}

export default SingleMenuItem
