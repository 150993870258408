import tw, { css } from 'twin.macro'

export const divWrapperStyle = tw`
  h-full w-full lg:absolute lg:left-0 overflow-auto bg-white
`

export const tabWrapperStyle = tw`
  absolute top-0 left-0 right-0 bottom-0 z-10 lg:right-auto lg:left-[30rem] lg:w-[30rem] h-[100vh] overflow-auto
`

export const menuContainerStyle = css`
  ${tw`m-auto fixed left-0 top-0 w-full bg-transparent lg:min-w-[23.4375rem] lg:w-[30rem]`}
`

export const buyContainerStyle = css`
  ${tw`bg-[#bad7f1] w-full h-[100vh]`}

  div > #find-us-website {
    ${tw`flex-col items-center justify-center bg-[#bad7f1]`}
  }
`
