import { useSidePanel } from '@okam/shared-ui/src'
import tw from 'twin.macro' // eslint-disable-line
import { ButtonWithForwardRef } from '../../Button'
import MenuBtn from '../../icons/MenuBtn'

const OpenButton = () => {
  const { buttonProps } = useSidePanel()

  return (
    <div tw="absolute top-6 h-auto left-6 lg:left-12 z-40">
      <ButtonWithForwardRef
        tw="rounded-full aspect-square text-black !p-0"
        {...buttonProps?.openButtonProps}
        ref={buttonProps?.openButtonRef}
      >
        <MenuBtn tw="text-black" />
      </ButtonWithForwardRef>
    </div>
  )
}

export default OpenButton
