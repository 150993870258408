import tw, { css } from 'twin.macro'
import Heading from '../Heading'
import type { TPageTitle } from './interface'

const PageTitle = (props: TPageTitle) => {
  const { children, as, variant, ...rest } = props

  return (
    <Heading
      {...rest}
      tw="flex justify-center pt-48 pb-20 relative z-20 font-semibold text-center"
      as={as ?? 'h1'}
      variant={variant ?? 'h1'}
      css={css`
        & {
          ${tw`font-semibold`}
        }
        strong {
          ${tw`block font-black`}
        }
      `}
    >
      {children}
    </Heading>
  )
}

export default PageTitle
