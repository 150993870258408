import type { MenuItem } from '@okam/shared-ui'
import { Item } from 'react-stately'
import type { TSubMenuExtraData, TSubMenuTab } from '../interface'
import MenuItems from './MenuItems'

const menuTabs = (menu: MenuItem[], extras?: TSubMenuExtraData[]) => {
  const tabs: TSubMenuTab[] = [{ key: 'empty', title: 'empty', childItems: null, extra: undefined }]

  const recursiveFilter = (x: MenuItem) => {
    if (x.path?.startsWith('#')) {
      const extra = extras?.find((f) => x.path === f.path)
      tabs.push({ key: x.path.substring(1), title: x.label ?? x.path, childItems: x.childItems, extra })
    }

    if (x?.childItems != null) {
      x.childItems.forEach(recursiveFilter)
    }
  }

  menu.forEach(recursiveFilter)

  return tabs.map(({ childItems, key, title, extra }) => (
    <Item key={key} title={title}>
      <div css={extra?.containerStyle}>
        <MenuItems menuItems={childItems} size="small" />
        {extra?.data != null && extra?.data}
      </div>
    </Item>
  ))
}

export default menuTabs
