import Link from 'next/link'
import LogoFr from '../icons/LogoFr'
import type { TLogoProps } from './interface'

const Logo = (props: TLogoProps) => {
  const { color, link } = props

  return link ? (
    <Link href={link} css={{ color: `${color}` }} {...props}>
      <LogoFr tw="text-inherit" />
    </Link>
  ) : (
    <LogoFr tw="text-inherit" {...props} />
  )
}

export default Logo
