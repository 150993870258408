import React from 'react'
import { useSidePanel } from '../../../providers/SidePanel'
import { ButtonWithForwardRef } from '../../Button'
import type { TCloseBtnProps } from '../interface'

const CloseBtn = ({ closeBtnStyle, variant = 'black' }: TCloseBtnProps) => {
  const { buttonProps } = useSidePanel()
  const { closeButtonProps, closeButtonRef } = buttonProps
  return (
    <ButtonWithForwardRef
      css={closeBtnStyle}
      variant={variant}
      type="button"
      {...closeButtonProps}
      ref={closeButtonRef}
      icon="CloseBtn"
      aria-label="CloseButton"
    />
  )
}

export default CloseBtn
