import React from 'react'
import { animated, useTransition } from 'react-spring'

interface TProps {
  isVisible: boolean
  children: React.ReactNode
}

const RenderWithSlideLeft = ({ isVisible, children }: TProps) => {
  const transition = useTransition(isVisible, {
    from: { opacity: 0, translateX: '-100%', height: '100%' },
    enter: { opacity: 1, translateX: '0%', height: '100%' },
    leave: { opacity: 0, translateX: '-25%', height: '100%' },
  })

  return transition((styles, bool) => bool && <animated.div style={styles}>{children}</animated.div>)
}

export default RenderWithSlideLeft
