import Link from 'next/link'
import React from 'react'
import tw, { styled, theme } from 'twin.macro'
import Button from '../Button'
import Heading from '../Heading'
import Logo from '../Logo'
import Paragraph from '../Paragraph'
import WysiwygBlock from '../WysiwygBlock'
import type { TFooterProps } from './interface'
import { baseStyle } from './styles'

const StyledFooter = styled.div(() => [baseStyle])

export const StyledBottomLink = styled(Link)(() => [
  tw`font-body text-footnote font-semibold block mb-1 transition duration-300 ease-in-out hover:underline lg:mr-4`,
])

const StyledCopyrightText = styled.span(() => [tw`mr-1 mb-2`])

const Copyright = ({ rightsReservedLabel }: { rightsReservedLabel: string }) => {
  const year = new Date().getFullYear()

  return (
    <Paragraph tw="flex flex-row flex-wrap justify-start items-end" variant="footnote">
      <StyledCopyrightText>© Petstory</StyledCopyrightText>
      <StyledCopyrightText>{year}</StyledCopyrightText>
      <StyledCopyrightText>{rightsReservedLabel}</StyledCopyrightText>
    </Paragraph>
  )
}

const Footer = (props: TFooterProps) => {
  const {
    children,
    bottomLinks,
    address,
    addressTitle,
    homeLink,
    ariaLabel,
    facebookLink,
    instagramLink,
    rightsReservedLabel,
    locale,
    onSocialMediaClick,
    ...rest
  } = props

  return (
    <StyledFooter {...rest}>
      <div tw="flex items-center justify-center py-16">
        <Logo color={theme('colors.white')} aria-label={ariaLabel} link={homeLink} />
      </div>
      <div tw="lg:mt-20">
        {children}
        <div tw="mt-10 lg:grid lg:grid-cols-2">
          {address && (
            <div tw="mb-6">
              {addressTitle && (
                <Heading as="p" variant="h6" tw="!font-semibold mb-1">
                  {addressTitle}
                </Heading>
              )}
              <WysiwygBlock content={address} />
            </div>
          )}
          <div>
            {(facebookLink || instagramLink) && (
              <>
                {facebookLink && (
                  <Button
                    as="a"
                    role="link"
                    variant="primary"
                    icon="Facebook"
                    iconPosition="left"
                    color={theme('colors.black')}
                    backgroundColor="#ABD2EF"
                    hoverColor="#5A9EDB"
                    href={facebookLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    tw="mb-2 w-fit"
                    handlePress={() => onSocialMediaClick?.('facebook', facebookLink)}
                  >
                    facebook
                  </Button>
                )}
                {instagramLink && (
                  <Button
                    as="a"
                    role="link"
                    variant="primary"
                    icon="Instagram"
                    iconPosition="left"
                    color={theme('colors.black')}
                    backgroundColor="#ABD2EF"
                    hoverColor="#5A9EDB"
                    href={instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    tw="w-fit"
                    handlePress={() => onSocialMediaClick?.('instagram', instagramLink)}
                  >
                    instagram
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        <div tw="mt-8 mb-6 2xl:mb-8">
          <Copyright rightsReservedLabel={rightsReservedLabel ?? ''} />
          {bottomLinks}
          <div />
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
