import { useSidePanel } from '@okam/shared-ui'
import React from 'react'
import { useMedia } from 'react-use'
import { theme } from 'twin.macro'
import Button from '../../Button'

const TabBackButton = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMedia(`(max-width: ${theme`screens.lg`})`, true)
  const { tabState, defaultSelectedKey } = useSidePanel()

  if (!isMobile) {
    return null
  }

  return (
    <Button
      tw="absolute z-10 top-20"
      icon="ArrowLeft"
      iconPosition="left"
      buttonStyle="hollow"
      handlePress={() => tabState.setSelectedKey(defaultSelectedKey)}
    >
      {children}
    </Button>
  )
}

export default TabBackButton
