import tw from 'twin.macro'
import Button from '../Button'
import Heading from '../Heading'
import PageTitle from '../PageTitle'
// eslint-disable-next-line import/order
import Link from 'next/link'
import type { LogoProps, HeroProps } from './interface'

export const HeroImg = (props: LogoProps) => {
  const { link, ariaLabel, children, onClick, text } = props
  return (
    <Link href={link} aria-label={ariaLabel} passHref onClick={() => onClick?.()}>
      <div tw="flex flex-col items-center justify-center bg-white rounded-4xl transition duration-300 hover:cursor-pointer mx-5 my-6 hover:-rotate-4 relative aspect-square">
        <div tw="w-1/2 h-fit">{children}</div>
        {text && (
          <Button tabIndex={-1} buttonStyle="hollow" variant="textIcon" icon="ArrowRight" tw="absolute bottom-0">
            {text}
          </Button>
        )}
      </div>
    </Link>
  )
}

export const LocationHero = (props: HeroProps) => {
  const { title, subtitle, children, ...rest } = props
  return (
    <div css={[tw`text-center relative font-semibold whitespace-pre-line pt-32 xl:pb-20`]} {...rest}>
      {title && <PageTitle tw="pt-8 pb-0">{title}</PageTitle>}
      {subtitle && (
        <Heading variant="h3" as="h3" tw="pt-10">
          {subtitle}
        </Heading>
      )}
      {children}
      <div tw="flex flex-row justify-between items-start">
        <svg
          width="409"
          height="394"
          viewBox="0 0 409 394"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          tw="xl:absolute xl:bottom-0 xl:left-0 xl:w-auto xl:h-1/2"
        >
          <path
            d="M316.59 226.22C295.479 207.095 284.678 184.161 261.383 169.422C261.383 169.422 257.608 181.418 260.954 194.681C267.468 197.633 273.564 200.49 277.788 202.678C292.301 210.204 316.6 226.22 316.6 226.22H316.59Z"
            fill="#EEEDEC"
          />
          <path
            d="M401.743 132.743C396.599 129.823 376.356 131.916 362.617 145.001C348.888 158.075 315.816 225.257 315.816 225.257C315.816 225.257 292.322 198.083 277.809 190.556C273.585 188.369 261.645 193.833 261.645 193.833C261.645 193.833 272.111 256.136 294.779 267.787C306.626 273.879 319.873 281.541 329.336 279.123C338.265 276.841 344.706 265.432 345.909 260.229C353.656 226.764 379.263 180.769 379.263 180.769L401.586 164.617C415.158 153.019 406.887 135.674 401.732 132.754L401.743 132.743Z"
            fill="#EEEDEC"
          />
          <path
            d="M390.618 167.475C393.305 165.183 394.675 155.741 391.329 152.443C388.129 149.293 376.879 149.481 374.892 152.328C372.906 155.165 387.147 170.437 390.618 167.475Z"
            fill="#554746"
          />
          <path
            d="M396.284 155.081C396.023 156.808 398.898 160.043 400.801 159.572C402.694 159.111 406.081 152.737 404.461 150.915C403.279 149.596 396.546 153.344 396.284 155.081Z"
            fill="#554746"
          />
          <path
            d="M392.384 147.116C392.091 149.251 395.249 151.878 397.152 151.418C399.045 150.957 402.777 144.792 401.157 142.981C399.975 141.662 392.687 144.991 392.384 147.126V147.116Z"
            fill="#554746"
          />
          <path
            d="M383.603 142.855C383.07 144.949 386.468 147.555 388.402 147.304C390.337 147.053 394.665 141.578 393.254 139.6C392.229 138.155 384.136 140.772 383.603 142.866V142.855Z"
            fill="#554746"
          />
          <path
            d="M164.35 50.6034C164.35 50.6034 158.495 1.98097 172.736 0.0653815C186.977 -1.85021 208.6 38.9633 208.6 38.9633L164.34 50.5929L164.35 50.6034Z"
            fill="#5F5152"
          />
          <path
            d="M176.638 41.0464C176.638 41.0464 173.292 12.8045 177.579 12.2183C181.866 11.6426 197.884 36.4929 197.884 36.4929L176.638 41.0464Z"
            fill="#E6BFC9"
          />
          <path
            d="M155.39 53.1364C155.39 53.1364 116.19 25.3657 109.697 33.1746C103.288 40.8788 105.013 79.6616 117.309 101.853L155.4 53.1469L155.39 53.1364Z"
            fill="#5F5152"
          />
          <path
            d="M146.91 61.5734C146.91 61.5734 124.095 44.1133 121.23 46.4685C118.366 48.8238 121.941 79.3999 129.836 89.6373L146.91 61.5839V61.5734Z"
            fill="#E6BFC9"
          />
          <path
            d="M239.216 418.606C240.471 407.405 241.913 394.017 247.058 383.989C272.288 334.77 290.816 293.726 292.823 241.555L261.382 169.422C241.527 171.945 199.609 182.81 158.664 193.32C154.952 200.584 145.834 226.419 139.205 245.658C132.838 264.155 122.528 310.746 107.744 334.215C92.959 357.684 67.823 393.682 60.8594 426.666C53.8958 459.649 66.2651 471.541 61.9677 540.575C59.3433 582.749 58.9983 602.617 50.6649 628.87C42.3316 655.123 34.1237 677.995 32.7121 682.758C31.3006 687.521 31.8861 703.149 47.0053 708.372C62.1246 713.596 77.0869 696.251 77.6306 689.844C78.3626 681.313 72.0681 643.87 79.5963 630.063C87.1246 616.256 117.698 564.724 117.698 564.724C117.698 564.724 123.396 533.604 124.536 517.693L164.603 497.856L239.205 418.595L239.216 418.606Z"
            fill="#EEEDEC"
          />
          <path
            d="M270.29 193.267C257.994 188.285 245.834 191.122 233.412 194.23C210.137 200.04 197.904 244.036 191.306 245.198C184.709 246.37 152.954 219.939 152.954 219.939C147.956 232.615 142.498 237.211 139.571 245.731C133.203 264.228 122.245 310.129 107.461 333.597C106.174 335.628 104.177 339.124 102.703 341.333C115.867 352.146 124.462 376.18 127.661 397.754C138.703 394.551 149.828 391.599 161.026 388.95C158.381 395.44 155.139 403.029 153.665 405.081C149.085 411.477 118.648 447.904 121.451 468.965C124.514 492.015 124.545 517.693 124.545 517.693L164.612 497.856L197.088 522.738C197.088 522.738 196.858 541.81 180.516 561.123C178.101 563.981 171.534 569.235 166.536 573.286C162.73 576.364 161.653 581.713 163.954 586.026C163.954 586.026 195.98 651.941 205.014 662.921C211.915 671.316 228.153 672.583 236.873 666.397C244.485 660.985 239.978 648.424 238.243 644.048C236.476 639.599 197.758 600.084 197.758 590.652C197.758 581.221 243.168 550.519 261.685 516.374C264.801 510.637 262.03 493.879 255.307 478.973C248.594 464.067 237.354 435.322 239.926 412.409C243.408 381.319 267.289 354.334 279.251 326.144C291.265 297.829 290.669 282.777 294.234 252.211C294.192 220.672 281.687 198.009 270.28 193.246L270.29 193.267Z"
            fill="#DDD8CE"
          />
          <path
            d="M250.549 48.3525C245.854 45.924 229.219 48.8236 229.219 48.8236C229.219 48.8236 220.467 40.6692 210.283 37.8116C202.765 35.6971 171.931 44.8877 171.931 44.8877L148.269 59.5111L117.299 101.853L155.881 195.099L261.37 169.422L258.568 102.303C258.568 102.303 267.864 81.8492 267.665 77.5051C265.689 69.9788 255.243 50.781 250.549 48.3525Z"
            fill="#EEEDEC"
          />
          <path
            d="M14.2561 257.644C12.8341 257.581 11.3807 257.55 9.89601 257.56C0.0570202 257.623 -9.00824 259.832 -17.2266 263.338C-11.0576 271.524 -2.52559 288.084 -4.78406 315.164C2.62917 316.922 9.30002 319.686 14.3502 323.883C37.144 342.84 29.9399 393.546 62.0813 410.776C63.8798 411.016 71.5021 414.931 73.3423 414.963C74.0533 414.973 74.4297 414.963 74.4297 414.963L95.216 366.927C95.216 366.927 83.3904 358.165 72.3595 320.021C62.4368 285.708 44.0449 258.921 14.2457 257.623L14.2561 257.644Z"
            fill="#554746"
          />
          <path
            d="M132.106 244.884C125.759 264.678 126.062 285.205 119.444 304.989C115.596 316.504 110.41 327.055 105.339 335.094C90.5541 358.563 62.0304 396.896 56.7293 430.193C50.5394 469.112 68.0112 489.44 59.9184 573.601C59.9184 573.601 87.3546 546.563 96.744 519.116C101.146 506.241 102.829 489.011 103.373 474.336C104.084 455.065 114.184 437.385 130.464 427.063L137.417 422.657C152.338 413.194 162.396 397.691 164.958 380.2C164.958 380.2 162.041 245.637 167.875 230.512C177.348 205.933 208.831 185.249 208.831 185.249C191.715 182.685 155.882 195.099 155.882 195.099C155.882 195.099 141.349 216.045 132.106 244.884Z"
            fill="#5F5152"
          />
          <path
            d="M216.849 40.3868C214.559 39.2144 212.97 38.2618 207.836 37.058C202.797 35.8856 193.156 36.6602 186.37 38.356C177.044 40.6903 167.654 44.4482 159.331 49.2424C151.918 53.5028 145.111 58.9983 139.904 65.8232C135.095 72.1143 131.017 79.1381 126.866 85.8793C123.614 91.1655 120.404 96.4936 117.299 101.864C127.807 153.176 140.385 177.231 155.39 200.218L208.83 185.26C206.174 175.682 202.222 170.584 170.362 156.777C157.47 151.187 150.13 136.993 146.554 127.635C144.651 122.652 146.46 117.01 150.873 114.027C154.532 111.557 156.456 107.202 155.808 102.827C152.242 78.4263 151.343 70.0522 157.962 63.782C165.187 56.9362 192.403 59.7624 192.403 59.7624C189.329 51.3255 186.318 44.1132 190.469 40.7636C194.631 37.4244 216.849 40.3868 216.849 40.3868Z"
            fill="#5F5152"
          />
          <path
            d="M180.63 75.7678C182.617 78.9081 181.686 83.0638 178.56 85.0527C175.423 87.0415 171.272 86.1099 169.285 82.9801C167.299 79.8502 168.229 75.6841 171.356 73.6952C174.492 71.7064 178.643 72.638 180.63 75.7678Z"
            fill="#2A2927"
          />
          <path
            d="M215.794 47.2852C217.582 50.1011 216.745 53.838 213.933 55.628C211.12 57.418 207.387 56.5806 205.599 53.7648C203.811 50.9489 204.648 47.212 207.46 45.422C210.273 43.632 214.006 44.4694 215.794 47.2852Z"
            fill="#2A2927"
          />
          <path
            d="M190.627 135.057C190.114 131.393 225.591 92.4111 236.235 80.6036C247.141 68.5029 276.114 32.4208 280.15 32.0753C284.186 31.7404 286.716 33.5303 285.995 36.3252C285.273 39.1201 254.251 75.0347 245.74 84.2777C237.709 92.9973 200.225 137.443 197.005 137.946C193.795 138.448 191.013 137.862 190.627 135.057Z"
            fill="#A96152"
          />
          <path
            d="M256.468 55.0624C255.307 53.3248 254.46 52.2466 254.46 52.2466L232.88 62.7247L202.338 101.686L212.637 102.983C212.637 102.983 228.174 96.7866 233.141 91.7412C238.107 86.6853 240.983 75.5163 240.983 75.5163C240.983 75.5163 250.832 72.0724 253.969 68.3982C256.604 65.3103 257.482 58.9564 257.482 58.9564C257.482 58.9564 257.618 56.821 256.468 55.0729V55.0624Z"
            fill="#EEEDEC"
          />
          <path
            d="M216.87 71.1616C213.305 71.5385 210.931 67.5398 213.001 64.6089C215.354 61.2697 218.323 57.5641 221.251 55.3136C224.524 52.8013 228.487 50.8962 231.561 49.6296C234.133 48.5724 237.008 50.2995 237.28 53.0735C237.792 58.2026 238.075 65.4777 235.795 67.3305C233.62 69.089 224.095 70.408 216.87 71.1616Z"
            fill="#2A2927"
          />
          <path
            d="M196.073 341.825C196.073 341.825 159.112 325.851 136.443 295.432L170.54 224.712L195.979 288.419C202.012 305.586 202.159 323.381 196.084 341.825H196.073Z"
            fill="#EEEDEC"
          />
          <path
            d="M194.902 300.425C194.902 300.425 199.764 236.468 203.581 228.994C212.824 210.874 223.478 201.223 231.906 201.39C240.333 201.547 252.546 211 247.328 227.329C242.111 243.659 232.272 245.836 231.676 252.609C231.08 259.381 229.647 283.08 228.215 301.053C228.215 301.053 230.452 335.733 196.073 341.825L194.902 300.436V300.425Z"
            fill="#EEEDEC"
          />
          <path
            d="M239.977 235.306C241.096 231.956 237.405 223.153 232.846 222.064C228.486 221.017 218.94 226.994 218.699 230.459C218.459 233.924 238.534 239.65 239.977 235.316V235.306Z"
            fill="#554746"
          />
          <path
            d="M238.44 221.771C239.109 223.393 243.239 224.67 244.619 223.289C245.999 221.907 245.623 214.705 243.291 213.983C241.597 213.46 237.76 220.148 238.429 221.771H238.44Z"
            fill="#554746"
          />
          <path
            d="M230.995 216.956C231.842 218.934 235.909 219.552 237.29 218.18C238.67 216.799 238.701 209.597 236.369 208.885C234.676 208.362 230.148 214.977 230.995 216.966V216.956Z"
            fill="#554746"
          />
          <path
            d="M221.272 217.835C221.899 219.897 226.145 220.379 227.682 219.164C229.208 217.95 230.097 211.031 227.859 210.057C226.228 209.346 220.655 215.773 221.272 217.835Z"
            fill="#554746"
          />
          <path
            d="M171.368 222.074C156.803 249.981 175.613 265.599 170.364 285.676C165.115 305.753 153.499 313.897 153.499 313.897C145.552 307.951 135.807 298.74 127.809 290.041L171.368 222.074Z"
            fill="#5F5152"
          />
          <path
            d="M294.528 267.002L293.221 266.908C294.005 256.084 293.346 245.313 293.336 245.198L294.643 245.114C294.643 245.219 295.322 256.084 294.528 267.002Z"
            fill="#E9E5E1"
          />
        </svg>
        <svg
          width="376"
          height="401"
          viewBox="0 0 376 401"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          tw="xl:absolute xl:bottom-0 xl:right-0 xl:w-auto xl:h-1/2"
        >
          <path
            d="M119.238 386.47L4.79082 372.244C2.45668 371.948 0.314585 373.617 0.0338969 375.952V375.952C-0.261564 378.286 1.40779 380.428 3.74193 380.709L118.189 394.935C120.523 395.23 122.665 393.561 122.946 391.227C123.241 388.893 121.572 386.751 119.238 386.47V386.47Z"
            fill="#633330"
          />
          <path
            d="M361.353 77.433C359.654 88.5867 350.539 95.5743 350.539 95.5743L386.305 101.646C386.305 101.646 388.934 92.9595 390.604 82.5888C383.202 76.0148 371.029 71.5977 361.338 77.433H361.353Z"
            fill="#757168"
          />
          <path
            d="M302.144 11.0577C301.213 10.6884 300.267 10.3043 299.352 9.89062C290.621 6.00531 283.027 -5.34038 273.676 2.97684C266.097 9.69857 272.819 18.7988 282.791 26.658C285.022 28.416 287.592 29.982 290.355 31.4149C289.365 23.0977 295.082 15.7112 302.158 11.0577H302.144Z"
            fill="white"
          />
          <path
            d="M302.146 11.0576C295.07 15.7111 289.352 23.0976 290.342 31.4149C296.576 34.6354 303.963 37.0434 311.689 38.7866C312.059 30.1887 315.131 21.7385 321.543 16.1986C314.925 14.9872 308.38 13.4952 302.146 11.0576V11.0576Z"
            fill="#959084"
          />
          <path
            d="M341.352 19.5813C334.778 18.3108 328.116 17.3949 321.542 16.1982C313.047 20.2313 310.048 31.4145 311.688 38.7862C317.243 40.0419 322.96 40.9431 328.529 41.5488C330.095 41.7261 331.617 41.9772 333.109 42.2874C329.445 33.7338 333.966 24.7814 341.352 19.5813V19.5813Z"
            fill="#757168"
          />
          <path
            d="M362.301 25.8752C355.594 22.7877 348.517 20.9853 341.338 19.5967C333.951 24.7968 329.431 33.7492 333.094 42.3028C338.413 43.4256 343.243 45.4347 347.365 48.2711C348.68 43.2779 352.151 39.1414 356.894 36.2902C360.646 34.0447 365.196 31.4003 369.909 30.056C367.471 28.49 364.915 27.1014 362.286 25.89L362.301 25.8752Z"
            fill="#959084"
          />
          <path
            d="M369.922 30.0411C365.756 29.2434 360.822 31.6661 357.069 33.9264C352.312 36.7776 348.678 43.2777 347.363 48.2563C352.549 51.8018 356.611 56.6178 359.093 62.4975C364.869 53.4416 377.899 49.2904 388.521 50.6495C384.68 42.155 377.929 35.1673 369.922 30.0411V30.0411Z"
            fill="#757168"
          />
          <path
            d="M391.861 63.5169C391.507 58.9224 390.325 54.6235 388.537 50.6495C377.915 49.2904 364.886 53.4416 359.109 62.4975C360.011 64.6396 360.734 66.8999 361.207 69.3227C361.769 72.2182 361.754 74.9364 361.37 77.4331C371.046 71.6125 383.234 76.0149 390.635 82.5889C391.654 76.3251 392.319 69.4556 391.861 63.5021V63.5169Z"
            fill="#959084"
          />
          <path
            d="M377.088 237.78C377.473 256.083 375.774 300.831 370.145 307.434C364.325 314.26 353.2 316.269 344.071 313.949C336.994 312.147 336.374 305.13 338.383 299.413C340.377 293.695 346.287 288.392 346.287 288.392L340.57 251.651L377.103 237.78H377.088Z"
            fill="#E9E3DC"
          />
          <path
            d="M253.023 169.868C253.023 169.868 303.133 108.501 325.691 98.8833C346.373 90.0638 377.589 87.2717 400.428 104.305C423.282 121.338 424.848 139.421 427.921 155.036C430.979 170.592 429.679 182.971 436.046 192.219C443.477 203.004 457.747 211.011 459.683 216.816C461.618 222.622 453.271 261.416 450.627 279.528C447.554 300.535 445.944 314.806 437.848 321.41C430.89 327.082 417.772 325.31 410.902 322.857C404.033 320.405 403.959 312.339 406.662 306.917C409.366 301.496 414.64 299.413 414.64 299.413L419.012 259.688C419.012 259.688 388.802 248.372 381.445 240.616C374.088 232.875 376.023 214.497 376.023 207.805C376.023 201.113 253.008 169.868 253.008 169.868H253.023Z"
            fill="#959084"
          />
          <path
            d="M383.795 232.875C383.795 232.875 373.424 251.755 357.853 261.166C340.613 271.581 309.442 283.03 309.442 283.03L220.656 188.719C239.285 181.997 253.039 171.331 268.255 152.022C268.255 152.022 373.336 170.932 383.795 186.414C394.254 201.896 383.795 232.89 383.795 232.89V232.875Z"
            fill="#959084"
          />
          <path
            d="M390.619 208.529C391.269 196.386 393.544 175.674 391.993 156.646C389.836 129.996 378.683 114.351 357.735 99.9032C353.598 97.052 347.364 93.6099 342.917 93.6099C336.712 94.8508 331.069 96.2542 325.677 98.8838C322.442 100.465 318.438 102.843 314.184 106.123C324.539 105.679 335.486 109.018 345.355 115.829C361.753 127.13 372.138 145.744 373.852 166.899C375.3 184.7 376.644 196.238 376.009 207.805C376.009 218.664 377.368 228.99 380.012 238.814C380.441 239.464 380.913 240.07 381.43 240.616C383.927 243.231 389.024 246.26 394.697 249.126C390.9 235.623 389.807 223.066 390.59 208.515L390.619 208.529Z"
            fill="#757168"
          />
          <path
            d="M253.541 168.362C247.913 173.695 242.181 178.038 236.035 181.598C252.876 187.02 282.777 199.296 298.363 221.781C313.771 244.014 321.349 262.88 325.043 276.884C330.243 274.742 336.211 272.172 342.017 269.439C337.57 253.883 329.962 227.454 314.199 204.703C298.924 182.662 276.218 166.161 253.526 168.347L253.541 168.362Z"
            fill="#757168"
          />
          <path
            d="M289.855 128.578C284.522 134.058 276.663 142.627 271.906 147.916C283.016 148.58 308.174 156.853 319.904 170.06C341.901 194.82 350.573 234.589 351.43 264.8C353.719 263.588 355.891 262.377 357.871 261.18C361.52 258.979 364.888 256.246 367.887 253.365C367.887 224.986 361.194 180.209 333.894 151.151C322.194 138.697 302.161 128.578 289.855 128.578V128.578Z"
            fill="#757168"
          />
          <path
            d="M309.47 260.087C309.47 260.087 354.469 260.649 375.816 218.9L380.499 236.835C380.499 236.835 379.76 261.343 319.885 278.982L304.551 255.951L309.485 260.087H309.47Z"
            fill="white"
          />
          <path
            d="M459.714 216.817C457.779 211.011 443.508 203.004 436.077 192.22C434.895 190.506 433.979 188.674 433.241 186.739C436.919 208.145 427.583 242.537 407.373 242.212C401.937 242.123 387.71 242.049 382.082 235.741C376.453 229.433 376.054 207.805 376.054 207.805C376.054 214.497 374.119 232.875 381.476 240.616C388.833 248.357 419.044 259.688 419.044 259.688L414.671 299.413C414.671 299.413 409.412 301.496 406.694 306.917C403.99 312.339 404.049 320.39 410.933 322.858C417.803 325.31 430.921 327.083 437.879 321.41C445.975 314.821 447.585 300.55 450.658 279.528C453.302 261.416 461.649 222.622 459.714 216.817V216.817Z"
            fill="white"
          />
          <path
            d="M59.9544 294.582C49.436 301.304 40.8528 306.696 40.8528 306.696C40.8528 306.696 21.5445 311.261 18.058 315.899C14.5716 320.553 18.4126 333.243 26.5378 336.33C34.6629 339.433 49.4951 336.434 58.8612 332.652C66.5431 329.55 75.2445 324.911 84.389 320.981C80.2821 308.926 71.6398 297.374 59.9396 294.582H59.9544Z"
            fill="white"
          />
          <path
            d="M84.4477 278.701C77.135 283.561 67.9905 289.456 59.9688 294.582C71.669 297.374 80.2965 308.926 84.4181 320.981C87.2102 319.785 90.0467 318.632 92.8979 317.628C95.6752 316.653 100.668 314.791 106.917 312.472C103.062 298.955 96.3252 283.118 84.4624 278.686L84.4477 278.701Z"
            fill="white"
          />
          <path
            d="M230.508 349.36L219.414 366.305C219.414 366.305 204.995 373.514 203.547 378.197C202.085 382.88 205.734 392.069 216.193 393.62C226.652 395.172 235.014 392.852 240.436 389.366C244.749 386.588 254.101 375.257 259.744 368.24C257.868 352.847 242.327 350.173 230.508 349.36Z"
            fill="white"
          />
          <path
            d="M324.348 287.033C308.038 272.969 290 263.75 270.116 272.585C262.862 276.219 245.282 285.807 240.422 295.69C244.1 298.57 248.783 302.264 253.614 306.075C271.283 297.832 294.801 304.627 308.614 319.533C316.725 312.693 323.816 305.366 326.386 300.476C327.612 298.142 326.342 292.735 324.348 287.018V287.033Z"
            fill="#959084"
          />
          <path
            d="M299.35 245.254C284.68 229.373 258.813 206.785 235.855 204.998C191.315 201.511 136.329 231.722 136.329 231.722C136.329 231.722 125.973 242.861 116.844 252.005C134.601 262.48 143.583 278.257 145.607 298.304C157.159 294.419 173.026 289.573 179.393 288.406C198.317 290.593 217.64 291.287 233.167 289.987L236.934 292.942C236.934 292.942 275.447 241.664 299.35 245.254V245.254Z"
            fill="#959084"
          />
          <path
            d="M316.402 268.507C316.402 268.507 312.783 261.091 304.067 250.691C272.068 245.003 246.733 258.137 233.156 289.987C233.156 289.987 240.321 295.601 244.664 299.014C249.525 289.13 259.91 280.828 267.179 277.209C287.063 268.374 308.026 272.984 324.35 287.033C321.248 278.139 316.402 268.507 316.402 268.507V268.507Z"
            fill="#757168"
          />
          <path
            d="M153.75 265.257C172.216 279.306 193.371 285.792 193.371 285.792C193.371 285.792 213.507 276.499 226.729 266.188C244.796 252.109 260.352 243.57 262.568 217.511C264.267 197.464 246.79 175.378 246.79 175.378L136.347 231.708C136.347 231.708 134.057 234.352 130.231 238.459L128.414 240.394C128.414 240.394 137.307 252.715 153.765 265.242L153.75 265.257Z"
            fill="white"
          />
          <path
            d="M233.167 289.987C233.167 289.987 222.723 276.042 251.855 244.871C251.855 244.871 205.778 275.938 196.338 276.411C186.898 276.899 132.164 245.077 132.164 245.077C132.164 245.077 159.908 283.901 179.393 288.407C179.393 288.407 205.291 293.474 233.167 289.987V289.987Z"
            fill="white"
          />
          <path
            d="M240.436 240.586C240.436 240.586 228.13 250.528 204.212 263.942C170.485 282.852 138.147 246.909 138.147 246.909L133.789 234.588L240.421 240.601L240.436 240.586Z"
            fill="#E9E3DC"
          />
          <path
            d="M219.414 366.305C219.414 366.305 204.995 373.514 203.547 378.197C202.085 382.88 205.734 392.069 216.193 393.62C226.652 395.171 235.014 392.852 240.436 389.366C242.43 388.08 245.119 385.466 247.985 382.319C243.479 373.16 227.48 367.96 219.428 366.305H219.414Z"
            fill="white"
          />
          <path
            d="M38.7411 307.242C33.6297 308.572 20.851 312.221 18.0737 315.914C14.5872 320.567 18.4282 333.258 26.5534 336.345C34.6786 339.447 49.5107 336.448 58.8768 332.667C60.3245 332.076 61.8166 331.44 63.3235 330.761C61.8166 319.977 49.6289 309.177 38.7411 307.242V307.242Z"
            fill="white"
          />
          <path
            d="M240.218 334.528L230.512 349.36C242.33 350.158 255.744 356.052 259.748 368.24C261.195 366.453 260.693 367.103 261.801 365.714C264.519 362.301 268.597 357.751 273.206 352.847C268.316 339.063 250.381 331.027 240.218 334.528V334.528Z"
            fill="white"
          />
          <path
            d="M248.343 237.07C262.303 222.312 258.654 196.179 254.045 185.527C249.451 174.876 245.772 166.101 245.772 166.101C245.772 166.101 251.342 133.216 245.89 129.73C240.439 126.243 208.426 150.161 208.426 150.161C188.66 148.949 168.657 148.964 148.433 150.161C148.433 150.161 113.376 131.384 110.023 135.476C106.669 139.554 119.743 176.752 119.743 176.752C119.743 176.752 113.036 215.457 117.232 225.74C121.428 236.022 131.562 247.53 146.822 255.492C154.534 259.511 161.551 265.907 178.54 265.523C191.792 265.228 210.184 257.058 210.184 257.058C210.184 257.058 232.196 254.104 248.313 237.07H248.343Z"
            fill="#959084"
          />
          <path
            d="M235.15 221.913C245.964 214.246 254.591 203.403 256.128 191.776C259.526 205.397 258.388 215.029 256.364 222.312C252.183 225.99 245.211 231.855 240.557 234.987C228.355 243.201 221.308 244.014 207.067 244.014C206.357 239.124 200.788 236.612 198.941 231.914C211.233 234.352 225.385 228.827 235.135 221.913H235.15Z"
            fill="#757168"
          />
          <path
            d="M116.199 205.959C116.731 213.98 127.973 225.932 136.305 230.275C141.608 233.052 147.488 235.017 153.575 236.347C152.127 240.705 150.664 245.063 149.216 249.406C139.954 246.333 130.277 241.059 122.758 235.401C120.247 231.782 119.109 230.29 117.248 225.725C116.066 222.815 115.416 214.128 116.184 205.944L116.199 205.959Z"
            fill="#757168"
          />
          <path
            d="M221.01 216.388C218.203 223.332 210.285 226.67 203.356 223.864C196.413 221.057 193.074 213.138 195.881 206.21C198.688 199.266 206.606 195.928 213.535 198.735C220.478 201.541 223.817 209.46 221.01 216.388Z"
            fill="#A8CBD9"
          />
          <path
            d="M154.178 215.177C151.371 222.121 143.453 225.459 136.524 222.652C129.581 219.845 126.242 211.927 129.049 204.999C131.856 198.055 139.774 194.717 146.703 197.523C153.646 200.33 156.985 208.249 154.178 215.177Z"
            fill="#A8CBD9"
          />
          <path
            d="M148.932 213.05C147.292 217.098 142.683 219.048 138.65 217.408C134.602 215.768 132.652 211.159 134.292 207.126C135.932 203.078 140.541 201.128 144.574 202.768C148.607 204.408 150.572 209.017 148.932 213.05Z"
            fill="#3C3C3A"
          />
          <path
            d="M215.768 214.261C214.128 218.309 209.519 220.259 205.486 218.619C201.438 216.979 199.488 212.37 201.128 208.337C202.768 204.304 207.377 202.339 211.41 203.979C215.443 205.619 217.408 210.228 215.768 214.261Z"
            fill="#3C3C3A"
          />
          <path
            d="M174.834 192.249C174.834 192.249 165.941 213.079 155.171 221.307C148.996 226.02 129.555 225.488 129.555 239.7C129.555 246.215 141.609 254.296 149.099 257.088C156.84 260.958 164.966 266.483 176.873 266.469C187.657 266.469 197.304 262.731 206.522 258.846C213.923 257.561 226.436 254.251 225.727 242.123C224.634 223.523 204.38 231.279 192.34 221.692C183.934 215.014 174.834 192.234 174.834 192.234V192.249Z"
            fill="white"
          />
          <path
            d="M142.434 157.192C142.434 157.192 123.628 148.787 121.663 150.648C119.699 152.509 125.874 170.252 125.874 170.252C131.133 165.362 136.614 160.93 142.449 157.192H142.434Z"
            fill="#F9B4B1"
          />
          <path
            d="M237.397 164.89C237.397 164.89 240.544 146.748 238.653 144.237C236.762 141.726 218.133 154.297 218.133 154.297C224.692 157.355 231.325 160.576 237.397 164.89V164.89Z"
            fill="#F9B4B1"
          />
          <path
            d="M176.767 184.685C180.696 184.508 183.843 180.46 183.843 174.477C183.843 168.494 185.128 155.479 186.074 149.319C181.184 149.245 176.279 149.245 171.345 149.319C170.03 158.596 170.074 170.991 170.074 176.235C170.074 181.465 172.778 184.863 176.767 184.685V184.685Z"
            fill="#757168"
          />
          <path
            d="M149.628 176.605C150.012 177.831 150.499 179.028 151.09 180.15C152.966 183.711 158.802 188.763 162.288 184.095C163.795 182.071 163.204 178.998 163.13 176.679C162.362 169.78 162.362 162.777 163.234 155.893L163.204 156.041C163.234 155.819 163.263 155.583 163.293 155.346C163.278 155.524 163.248 155.686 163.234 155.864C163.248 155.76 163.263 155.627 163.293 155.435C163.411 154.726 163.514 154.002 163.647 153.293C163.884 152.008 164.164 150.737 164.46 149.467C159.422 149.615 154.384 149.821 149.317 150.117C148.342 150.176 147.988 162.083 148.017 163.413C148.091 167.712 148.298 172.395 149.613 176.605H149.628Z"
            fill="#757168"
          />
          <path
            d="M193.843 162.998C193.71 169.292 193.222 175.57 192.631 181.834C192.159 186.842 196.753 189.147 200.624 186.251C203.076 184.404 204.79 181.716 205.794 178.82C206.533 176.678 209.975 150.264 208.099 150.146C203.224 149.85 198.319 149.629 193.414 149.481C193.533 150.737 193.636 151.992 193.71 153.248C193.887 156.498 193.917 159.748 193.843 162.998V162.998Z"
            fill="#757168"
          />
          <path
            d="M179.014 229.492C180.166 230.792 176.015 236.952 171.967 237.1C167.919 237.262 163.34 231.56 164.359 230.053C165.378 228.546 177.862 228.192 179.014 229.492Z"
            fill="#F9B4B1"
          />
          <path
            d="M162.379 252.848C162.379 252.848 162.468 252.848 162.527 252.848C165.733 252.73 170.859 248.963 172.38 247.796C173.991 248.859 179.368 252.183 182.603 252.08C185.75 251.962 189.015 247.781 189.369 247.293L188.173 246.378C187.301 247.515 184.613 250.484 182.544 250.573C179.87 250.721 174.582 247.485 172.779 246.245L172.321 245.934L171.878 246.289C170.165 247.663 165.156 251.253 162.468 251.356C160.355 251.459 157.474 248.682 156.529 247.618L155.406 248.623C155.805 249.066 159.277 252.863 162.394 252.863L162.379 252.848Z"
            fill="#F9B4B1"
          />
          <path d="M172.728 236.982L171.223 237.04L171.599 246.886L173.105 246.829L172.728 236.982Z" fill="#F9B4B1" />
          <path
            d="M253.63 306.075L237.66 338.428C249.552 336.212 262.612 346.243 269.659 356.673C274.563 351.458 289.735 336.183 294.847 331.219C292.04 316.904 268.462 303.874 253.615 306.09L253.63 306.075Z"
            fill="#959084"
          />
          <path
            d="M246.82 319.873C258.742 314.142 280.828 324.808 286.914 339.078C292.764 333.598 306.873 321.011 308.631 319.519C294.818 304.613 271.314 297.817 253.631 306.061"
            fill="#757168"
          />
          <path
            d="M99.7082 268.153C94.7445 272.378 92.6467 273.205 87.5352 276.633C86.5454 277.297 75.3918 284.625 74.3281 285.334C83.4283 284.448 96.6354 305.012 95.1581 316.815C97.832 315.826 116.771 308.794 127.762 304.805C124.808 288.747 115.412 274.21 99.723 268.138L99.7082 268.153Z"
            fill="#959084"
          />
          <path
            d="M116.846 252.006C111.543 257.309 103.299 265.065 96.6367 270.723C109.312 271.255 121.825 296.34 119.225 307.523C126.877 304.745 138.031 300.875 145.595 298.319C143.585 278.272 134.603 262.494 116.831 252.02L116.846 252.006Z"
            fill="#757168"
          />
        </svg>
      </div>
    </div>
  )
}

export default LocationHero
