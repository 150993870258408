import type { TMenuItemsProps } from '@okam/shared-ui'
import { useSidePanel } from '@okam/shared-ui'
import React from 'react'
import tw from 'twin.macro'
import Button from '../../Button'
import MenuLink from './MenuLink'

const MenuFooter = ({ menuItems, children }: TMenuItemsProps & { children: React.ReactNode }) => {
  const { tabState } = useSidePanel()
  const { setSelectedKey } = tabState
  return (
    <div tw="w-full my-4">
      <div tw="w-full">
        {menuItems?.map((item, i) => {
          if (item?.path == null || item?.label == null) {
            return null
          }

          const elementType = item.path.charAt(0) === '#' ? 'button' : 'a'
          const itemKey = item.path?.substring(1)

          const handlePress = () => {
            if (itemKey == null) {
              return
            }
            if (elementType === 'button') {
              setSelectedKey(itemKey)
            }
            item.onClick?.()
          }

          return (
            <MenuLink onClick={item.onClick} elementType={elementType} path={item.path} key={item.id}>
              <Button
                {...{ handlePress }}
                css={i === 0 ? tw`bg-[#1c7ee3]` : tw`bg-[#e3eff9] text-black border-[#BAD7F1]`}
                tw="w-full mb-4"
                as="button"
              >
                {item.label}
              </Button>
            </MenuLink>
          )
        })}
      </div>
      {children}
    </div>
  )
}

export default MenuFooter
