import React from 'react'
import Button from '../../Button/index'
import type { TFooterLinksProps } from './interface'

const FooterLinks = (props: TFooterLinksProps) => {
  const { children, ...rest } = props

  return (
    <Button {...rest} as="a" role="link" variant="textIcon" buttonStyle="hollow" icon="ArrowRight" tw="justify-start">
      {children}
    </Button>
  )
}

export default FooterLinks
