import { createCtx } from '@okam/core-lib'
import { useCallback, useMemo, useState } from 'react'
import type { TProductSearchPanelContext, TProductSearchProvider } from './interface'

export const [useSearchIcon, SearchIconProvider] = createCtx<TProductSearchPanelContext>()

export function ProductSearchContextProvider({ children, isSearching }: TProductSearchProvider) {
  const [isUserSearching, setIsUserSearching] = useState<boolean>(isSearching)

  const setIsSearching = useCallback(
    (searching: boolean) => {
      setIsUserSearching(searching)
    },
    [setIsUserSearching],
  )

  const value = useMemo<TProductSearchPanelContext>(
    () => ({
      isSearching: isUserSearching,
      setIsSearching,
    }),
    [isUserSearching, setIsSearching],
  )
  return <SearchIconProvider value={value}>{children}</SearchIconProvider>
}
