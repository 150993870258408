import { Footer, FooterLink, FooterLinksContainer, ProductSearchContextProvider, Button } from '@okam/brand-ui'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { type ReactNode, type FC } from 'react'
import type { NavigationsQuery, SettingsQuery } from 'gql/graphql'
import { trackImportantClick, usePageViewAnalytics } from 'gtm-analytics/tracking'
import Header from '../Header'
import MainMenu from '../Menu'

export type TDirectusProps = {
  settings?: Pick<SettingsQuery, 'settings'>['settings'] | null | undefined
  navigations?: Pick<NavigationsQuery, 'navigations'>['navigations'] | null | undefined
}

export interface TMainLayout {
  children?: ReactNode
  footerProps?: TDirectusProps | null | undefined
  logoColor?: string | null
}

export const MainLayout: FC<TMainLayout> = (props) => {
  const { children, footerProps, logoColor } = props
  const { navigations, settings } = footerProps ?? {}
  const {
    address,
    facebookLink,
    instagramLink,
    privacyPolicy: privacyPolicyLink,
    termsAndCondition: termsConditionLink,
  } = settings?.translations?.[0] ?? {}
  const { t } = useTranslation('common')

  usePageViewAnalytics()

  const router = useRouter()
  const { locale } = router
  const homeLink = t('URL.HOME')

  function urlLinkRedirection(url: string) {
    if (locale === 'en') return `/${locale}/${url}`
    return `/${url}`
  }

  return (
    <div tw="flex flex-col items-stretch justify-between min-h-min bg-white">
      <ProductSearchContextProvider isSearching={false}>
        <MainMenu>
          <Header logoColor={logoColor} searchLabel={t('MENU.SEARCH_ARIA_LABEL')} homeLink={homeLink} />
        </MainMenu>
      </ProductSearchContextProvider>
      <main tw="flex flex-col h-full">{children && children}</main>
      {footerProps && (
        <Footer
          homeLink={homeLink}
          address={address ?? ''}
          addressTitle={`${t('FOOTER.ADRESS_TITLE')}`}
          facebookLink={facebookLink ?? ''}
          instagramLink={instagramLink ?? ''}
          rightsReservedLabel={`${t('FOOTER.RIGHTS_RESERVED')}`}
          locale={locale}
          onSocialMediaClick={(socialMedia, link) => {
            trackImportantClick({
              click_type: 'réseau social',
              interaction_location: 'page',
              link_url: link,
              link_text: socialMedia,
              link_text_fr: socialMedia,
            })
          }}
          bottomLinks={
            <div tw="flex flex-col justify-start items-start lg:flex-row">
              {privacyPolicyLink && (
                <Button
                  href={privacyPolicyLink}
                  as="a"
                  role="link"
                  variant="primary"
                  buttonStyle="hollow"
                  tw="font-body text-footnote font-semibold min-h-0 h-fit block mb-1 transition duration-300 ease-in-out hover:underline lg:mr-4"
                >
                  {`${t('FOOTER.PRIVACY_POLICY')}`}
                </Button>
              )}
              {termsConditionLink && (
                <Button
                  href={termsConditionLink}
                  as="a"
                  role="link"
                  variant="primary"
                  buttonStyle="hollow"
                  tw="font-body text-footnote font-semibold min-h-0 h-fit block mb-1 transition duration-300 ease-in-out hover:underline lg:mr-4"
                >
                  {`${t('FOOTER.TERMS_CONDITIONS')}`}
                </Button>
              )}
            </div>
          }
        >
          <FooterLinksContainer>
            {navigations?.[0]?.translations?.[0]?.items?.map((item) => (
              <FooterLink
                key={`footerLink-${item?.id}`}
                href={urlLinkRedirection(item?.page?.translations?.[0]?.slug ?? '')}
              >
                {item?.title}
              </FooterLink>
            ))}
          </FooterLinksContainer>
        </Footer>
      )}
    </div>
  )
}
