/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { useMenu } from '../../providers/Menu'
import { useSidePanel } from '../../providers/SidePanel'
import SidePanel from '../SidePanel'
import CloseBtn from '../SidePanel/components/CloseBtn'
import TabContainer from '../TabList/components/TabContainer/TabContainer'
import type TMenuProps from './Menu.interface'
import MenuContainerStyle, {
  divWrapperStyle as defaultDivWrapperStyle,
  closeBtnStyle,
  innerDivStyle as defaultInnerDivStyle,
  tabWrapperStyle as defaultTabWrapperStyle,
} from './Menu.styles'

const closeBtnRender = () => <CloseBtn closeBtnStyle={closeBtnStyle} variant="black" />

const Menu = ({
  id = 'main-menu',
  children,
  beforeTabContent,
  TransitonAnimation,
  menuContainerStyle,
  innerDivStyle,
  divWrapperStyle,
  tabWrapperStyle,
}: TMenuProps) => {
  const { tabState, defaultSelectedKey } = useSidePanel()
  const { selectedKey } = tabState
  const { closeBtn } = useMenu()

  return (
    <SidePanel
      closeBtnRender={closeBtn ?? closeBtnRender}
      id={id}
      containerStyle={menuContainerStyle ?? MenuContainerStyle}
      innerDivStyle={innerDivStyle ?? defaultInnerDivStyle}
      TransitionAnimation={TransitonAnimation as (props: unknown) => JSX.Element}
      onCloseCallBack={() => {
        tabState.setSelectedKey(defaultSelectedKey)
      }}
    >
      <div css={divWrapperStyle ?? defaultDivWrapperStyle}>{children}</div>

      {selectedKey?.toString() === defaultSelectedKey ? null : (
        <div css={tabWrapperStyle ?? defaultTabWrapperStyle}>
          {beforeTabContent !== null && beforeTabContent}
          <TabContainer />
        </div>
      )}
    </SidePanel>
  )
}

export default Menu
