import {
  ArrowRight,
  Button,
  CloseButton,
  LottiePlayer,
  Img,
  MenuFooter,
  MenuHeader,
  MenuItems,
  MenuSearchResult,
  OpenButton,
  ProductCard,
  TabBackButton,
  menuTabs,
  Heading,
  Paragraph,
} from '@okam/brand-ui'
import { isHex, isPlainObject } from '@okam/core-lib'
import type { MenuItem } from '@okam/shared-ui'
import { Menu, MenuContextProvider, RenderWithSlideLeft } from '@okam/shared-ui'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import type { ChangeEvent } from 'react'
import tw from 'twin.macro'
import { trackInternalSearch, trackImportantClick } from 'gtm-analytics/tracking'
import { toDirectusUrl } from 'lib/directus'
import { MenuLocations, useNavigationsQuery, useProductSearch } from 'lib/react-query'
import useLottie from 'lib/react-query/useLottie'
import getUrl from 'lib/utils/getUrl'
import productLink from 'lib/utils/productLink'
import { useColor } from 'providers/Color'
import { useUrlTranslation } from 'providers/UrlTranslation'
import LinkHeroImg from '../where-to-find-us'
import type { TMenuProps } from './interface'
import { divWrapperStyle, menuContainerStyle, tabWrapperStyle, buyContainerStyle } from './styles'

const MainMenu = ({ children }: TMenuProps) => {
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [lastSearchedValue, setLastSearchedValue] = useState<string>('')
  const [isCardOpen, setIsCardOpen] = useState<{ isOpen: boolean; cardOpened: string }>({
    isOpen: false,
    cardOpened: '',
  })
  const { locale = 'fr', asPath, query } = useRouter()
  const { t } = useTranslation('common')

  const { data: primary } = useNavigationsQuery(MenuLocations.Primary, locale)
  const { data: secondary } = useNavigationsQuery(MenuLocations.Secondary, locale)

  const handleSearch = (toggleSearch: ChangeEvent<HTMLInputElement> | undefined) => {
    if (!toggleSearch) {
      setIsSearching(false)
      return
    }
    setIsSearching(toggleSearch?.target.value.length > 0 && searchValue !== lastSearchedValue)
    setSearchValue(toggleSearch?.target.value)
  }

  const { data: searchResults, isSuccess: searchResultsSuccess } = useProductSearch(searchValue, locale, () => {
    setLastSearchedValue(searchValue)
    if (isSearching && searchValue !== lastSearchedValue) {
      trackInternalSearch({
        search_type: 'recherche produit',
        search_term: searchValue,
      })
    }
  })

  const { urlTranslation } = useUrlTranslation()
  const nextLanguageBaseRoute = t('MENU.LANG.URL')

  const { slug } = query

  const langSwitchUrl = slug ? `${nextLanguageBaseRoute}${urlTranslation}` : `${nextLanguageBaseRoute}${asPath}`

  const animalRoutes: Record<string, string> = {
    cat: t('URL.CAT_ROUTE'),
    dog: t('URL.DOG_ROUTE'),
  }

  const noResultFoundText = t('SEARCH.NO_RESULTS')
  const loadingText = t('SEARCH.LOADING')

  const navigationToMenuItems = (items?: unknown[] | null | undefined, frItems?: any[] | null | undefined) => { /*eslint-disable-line */
    if (items == null) {
      return []
    }

    const menuItems = items
      .map((item, index) => {
        if (!isPlainObject(item)) {
          return null
        }

        const menuItem: MenuItem = {
          id: typeof item?.id === 'string' ? item?.id : null,
          label: typeof item?.title === 'string' ? item?.title : null,
          frLabel: typeof frItems?.[index]?.title === 'string' ? frItems?.[index]?.title : null,
          path: typeof item?.path === 'string' ? item?.path : null,
          childItems: Array.isArray(item?.children) ? item?.children : [],
        }

        return menuItem
      })
      ?.filter((s): s is MenuItem => s?.id != null && s?.label != null)

    return menuItems
  }

  const primaryMenuItems = navigationToMenuItems(primary?.[0]?.translations?.[0]?.items)
  const secondaryMenuItems = navigationToMenuItems(
    secondary?.[0]?.translations?.[0]?.items,
    secondary?.[0]?.frTranslations?.[0]?.items,
  )

  const trackedSecondaryMenuItems = secondaryMenuItems.map((item) => {
    if (item) {
      const isExternalLink = item?.path?.includes('/')
      return {
        ...item,
        onClick: () => {
          trackImportantClick({
            click_type: 'cta',
            interaction_location: 'menu',
            link_url: isExternalLink ? getUrl(typeof item?.path === 'string' ? item?.path : '') : null,
            link_text: item.label ?? '',
            link_text_fr: item.frLabel ?? '',
          })
        },
      }
    }
    return item
  })

  const { data: dogLottie } = useLottie('get-menu-dog-lottie', 'chien-large-2_corrected.json')
  const { data: catLottie } = useLottie('get-menu-cat-lottie', 'chat saumon_corrected.json')

  const tabs = menuTabs(
    [...primaryMenuItems, ...trackedSecondaryMenuItems],
    [
      {
        path: '#dog',
        data: (
          <div tw="absolute z-10 -bottom-20 min-w-[550px] -right-32 w-full lg:w-[150%] lg:-right-48">
            <LottiePlayer
              src={toDirectusUrl(dogLottie?.[0]?.lottie?.id, dogLottie?.[0]?.lottie?.filename) ?? ''}
              autoplay
              loop
              controls={false}
              hover={false}
              direction={1}
              keepLastFrame={false}
              speed={1}
              width="100%"
            />
          </div>
        ),
        containerStyle: tw`bg-[#bad7f1] w-full h-[100vh] overflow-hidden relative`,
      },
      {
        path: '#cat',
        data: (
          <div tw="absolute z-10 -bottom-20 min-w-[550px] -right-32 w-full lg:w-[150%] lg:-right-48">
            <LottiePlayer
              src={toDirectusUrl(catLottie?.[0]?.lottie?.id, catLottie?.[0]?.lottie?.filename) ?? ''}
              autoplay
              loop
              controls={false}
              hover={false}
              direction={1}
              keepLastFrame={false}
              speed={1}
              width="100%"
            />
          </div>
        ),
        containerStyle: tw`bg-[#FDD6B7] w-full h-[100vh] overflow-hidden relative`,
      },
      { path: '#human', data: <div>test chat</div>, containerStyle: tw`bg-[#bad7f1] w-full h-[100vh]` },
      {
        path: '#buy',
        data: (
          <div tw="text-center font-bold">
            <Heading variant="h3" as="h3">
              {t('MENU.BUY_ONLINE')}
            </Heading>
            <LinkHeroImg
              onTileClick={(text, textFr, link) =>
                trackImportantClick({
                  click_type: 'tuile',
                  interaction_location: 'menu',
                  link_url: link,
                  link_text: text,
                  link_text_fr: textFr,
                })
              }
            />
          </div>
        ),
        containerStyle: buyContainerStyle,
      },
    ],
  )

  const { colorScheme, setColorScheme } = useColor()

  const handleProductClicked = (title: string, backgroundColor: string) => {
    setIsCardOpen({
      isOpen: true,
      cardOpened: title,
    })
    setColorScheme({
      ...colorScheme,
      background: isHex(backgroundColor) ? backgroundColor ?? '#000000' : '#000000',
    })
  }

  return (
    <div tw="absolute w-full max-h-screen">
      <MenuContextProvider closeBtn={CloseButton} openBtn={OpenButton} defaultSelectedKey="empty" tabs={tabs}>
        <>
          <Menu
            id="main-menu"
            menuContainerStyle={menuContainerStyle}
            tabWrapperStyle={tabWrapperStyle}
            divWrapperStyle={divWrapperStyle}
            beforeTabContent={
              <div tw="px-12">
                <TabBackButton>{t('MENU.BACK_BUTTON_LABEL')}</TabBackButton>
              </div>
            }
            TransitonAnimation={RenderWithSlideLeft}
          >
            <div tw="overflow-y-auto h-min min-h-screen flex flex-col">
              <div
                tw="flex flex-col h-full justify-around items-center basis-full grow"
                css={isSearching ? tw`pt-32` : tw`lg:pt-[185px] pt-32 hsm:pt-20`}
              >
                <div tw=" w-full px-12">
                  <MenuHeader
                    searchAriaLabel={t('MENU.SEARCH_ARIA_LABEL') ?? null}
                    backBtnLabel={t('MENU.BACK_BUTTON_LABEL') ?? null}
                    searchInputPlaceholder={t('MENU.SEARCH_INPUT_PLACEHOLDER') ?? null}
                    searchCallback={handleSearch}
                    isSearching={isSearching}
                  />
                </div>
                <div
                  css={isSearching ? tw`mt-5` : tw`mt-5 lg:mt-20`}
                  tw="w-full h-full max-h-[40vh] sm:max-h-[65vh] overflow-auto grow"
                >
                  {isSearching ? (
                    <MenuSearchResult>
                      <div tw="flex flex-col h-full w-full">
                        {searchResults && searchResults?.length !== 0 ? (
                          searchResults.map((result, index) => (
                            <ProductCard
                              id={result?.product?.id ?? ''}
                              key={`${result?.product?.id}`}
                              ariaLabel={result.product?.translations?.[0]?.title ?? ''}
                              bgColor={result?.product?.colors?.background ?? 'white'}
                              labelColor={result?.product?.animals?.color ?? 'white'}
                              footnote={`${result?.product?.translations?.[0]?.sizes ?? ''}`}
                              title={result?.product?.translations?.[0]?.title ?? ''}
                              link={productLink(
                                animalRoutes[result?.product?.animals?.id ?? ''],
                                result?.product?.translations?.[0]?.slug ?? '',
                                index,
                              )}
                              linkAs={`${animalRoutes[result?.product?.animals?.id ?? '']}/${
                                result?.product?.translations?.[0]?.slug
                              }`}
                              labelText={result?.product?.animals?.translations?.[0]?.title ?? ''}
                              onClick={() =>
                                handleProductClicked(
                                  result.product?.translations?.[0]?.title ?? '',
                                  result.product?.colors?.background ?? 'white',
                                )
                              }
                              isCardOpen={isCardOpen}
                            >
                              {result?.product?.featuredImage?.id != null && (
                                <Img
                                  id={result?.product?.featuredImage?.id}
                                  filename={result?.product?.featuredImage.filenameDownload}
                                  alt={result?.product?.featuredImage?.filenameDownload ?? ''}
                                  fill
                                />
                              )}
                            </ProductCard>
                          ))
                        ) : (
                          <Paragraph tw="flex justify-center" as="p" variant="p">
                            {searchResultsSuccess ? noResultFoundText : loadingText}
                          </Paragraph>
                        )}
                      </div>
                    </MenuSearchResult>
                  ) : (
                    <MenuItems menuItems={primaryMenuItems} />
                  )}
                </div>
                <div tw="w-full px-12">
                  <MenuFooter menuItems={trackedSecondaryMenuItems}>
                    <div css={!isSearching && tw`pt-4 pb-10`} tw="flex justify-between  w-full">
                      <div>
                        <Button
                          iconPosition="left"
                          buttonStyle="hollow"
                          tw="w-full"
                          as="a"
                          href={`${t('URL.CONTACT_US')}`}
                        >
                          {t('MENU.CONTACT_US')}
                          <ArrowRight tw="ml-1 mt-0.5 inline align-baseline transform scale-75 origin-center" />
                        </Button>
                      </div>
                      <div>
                        <Button buttonStyle="hollow" tw="w-full" as="a" href={langSwitchUrl}>
                          {t('MENU.LANG.LABEL')}
                        </Button>
                      </div>
                    </div>
                  </MenuFooter>
                </div>
              </div>
            </div>
          </Menu>
          {children}
        </>
      </MenuContextProvider>
    </div>
  )
}

export default MainMenu
