import type { TMenuItemsProps } from '@okam/shared-ui'
import { useRouter } from 'next/router'
import tw from 'twin.macro'
import SingleMenuItem from './MenuItem'

const MenuItems = (props: TMenuItemsProps & { size?: string }) => {
  const { menuItems, size = 'large' } = props
  const { asPath } = useRouter()

  return (
    <ul
      css={[
        tw`flex flex-col justify-center items-start px-12 lg:relative lg:z-20`,
        size === 'small' && tw`pl-[51px] pt-[185px]`,
      ]}
    >
      {menuItems?.map((menuItem) => {
        if (menuItem?.path == null || menuItem.id == null) {
          return null
        }
        return (
          <li key={`li-${menuItem.id}`}>
            <SingleMenuItem
              {...menuItem}
              size={size}
              label={menuItem?.title ?? menuItem.label}
              active={menuItem.path === asPath}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default MenuItems
